import '../scss/style.scss';

$(function() {
	//  デバイス判定
	var ua = navigator.userAgent;
	var SP = false;
	var Tab = false;
	var PC = false;
	if( ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 ) {
		// sp
		$('body').addClass('sp');
		SP = true;
	} else if( ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 ) {
		// tab
		$('body').addClass('tab');
		Tab = true;
	} else {
		// pc
		$('body').addClass('pc');
		PC = true;
	}

	// タブレットのときはviewportを固定
	// if (Tab) {
	// 	$("meta[name='viewport']").attr('content', 'width=1160');
	// }

	// ページ内スムーススクロール
	$(document).on("click", "a[href^=\"#\"]" ,function(){
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "html" : href);
		var position = target.offset().top;
		// if (window.innerWidth > bp) {
		// 	position -= header.height();
		// }
		$("html, body").animate({scrollTop:position}, 500, "swing");
		return false;
	});

	// 画像のズーム
	var x = $(".imgZoom").width() * 0.70706;
	$(".imgZoom").height(x);
	$(".imgZoom").zoomer({
		marginMin: 0,
		marginMax: 0
	});

	// $(window).on("load", function() {
	// 	// フッターの高さをコンテンツの下部に余白として代入
	// 	setFooterPadding();
	// });

	// チェックした物件の親要素にアクティブクラスを付与
	$(".chkTbl").each(function() {
		chkTblActiveToggle($(this));
	});
	$(".chkTbl").on("change", function() {
		chkTblActiveToggle($(this));
	});

	// 検討中からお問い合わせできる物件数の制限
	favChkCnt($(".chkTbl"));
	$(".chkTbl").on("click", function() {
		favChkCnt($(".chkTbl"));
	});

	// ソート
	$(".toggleSort").on("click", function() {
		$(this).toggleClass("is-active");
		$(this).find(".toggleSortList").toggleClass("is-active");
	});
	$(".toggleSortItem").on("click", function() {
		var sortItemTxt = $(this).text();
		$(".toggleSortItemCurrent").text(sortItemTxt);
	});
	if (PC) {
		$(".toggleSort").hover(function() {
			$(this).addClass("is-active");
			$(this).find(".toggleSortList").addClass("is-active");
		}, function() {
			$(this).removeClass("is-active");
			$(this).find(".toggleSortList").removeClass("is-active");
		});
	}

	// 画像の保存禁止
	$(".imgGuard img").on("contextmenu", function() {
		return false;
	});
});

$(window).on("load", function() {
	// 画像の保存禁止
	// imageGuard(".imgGuard img, .zoomer-image");

	if ($(".footerFixed")[0]) {
		var footerFixedHeight = $(".footerFixedElem").outerHeight();
		$(".footerFixed").css("padding-bottom", footerFixedHeight);
	}
});

// 物件一覧の検索追従
$(window).on("load", function() {
	if ($(".fixedArticleListSearch")[0]) {
		var articleListSearchPos = $(".fixedArticleListSearch").offset().top;
		fixedArticleListSearch(articleListSearchPos);

		$(window).on("scroll", function() {
			fixedArticleListSearch(articleListSearchPos);
		});
	}
});

// ユーザメニュー開閉
$(document).on("click",".toggleUserMenu, .toggleUserMenu > *",function(e){
	e.stopPropagation();
});
$(document).on("click", ".toggleUserMenu", toggleUserMenu);

// ユーザメニューが開いている時にページ内をクリックしたらユーザメニューを閉じる
$(document).on("click", function() {
	if ($(".toggleUserMenu").hasClass("is-active")) {
		toggleUserMenu();
	}
});

// remodal
$(document).on('closed', '.remodal', function (e) {
	history.replaceState("", "", window.location.pathname);
});

// ユーザメニュー開閉
function toggleUserMenu() {
	$(".toggleUserMenu,.headerUserMenu").toggleClass("is-active");
}

// フッターの高さをコンテンツの下部に余白として代入
function setFooterPadding() {
	if ($(".global-footer")[0]) {
		var footerHeight = $(".global-footer").outerHeight();
		$(".main").css({
			"padding-bottom": footerHeight
		});
	}
}

// 画像の保存禁止
function imageGuard(selector) {
	

	// var guard_selector = document.querySelectorAll(selector);
  
	// for(var n = 0; n < guard_selector.length; n++) {
	// 	guard_selector[n].addEventListener("contextmenu", function(e){
	// 		e.preventDefault();
	// 	}, false);
	// }
	// window.addEventListener("contextmenu", function(e){
	// 	e.preventDefault();
	// }, false);

	// var guard_style = {
	// 	// 'pointer-events':'none',
	// 	// '-webkit-touch-callout':'none',
	// 	// '-moz-touch-callout':'none',
	// 	// 'touch-callout':'none',
	// 	'-webkit-user-select':'none',
	// 	'-moz-user-select':'none',
	// 	'user-select':'none'
	// }
  
	// Object.keys(guard_style).forEach(function(v, i, a) {
	// 	for(var n = 0; n < guard_selector.length; n++) {
	// 		guard_selector[n].style[v] = guard_style[v];
	// 	}
	// });

}

// チェックした物件の親要素にアクティブクラスを付与
function chkTblActiveToggle(chkElem) {
	if (chkElem.prop("checked") == true) {
		chkElem.parents(".chkTblParent").addClass("is-active");
	} else {
		chkElem.parents(".chkTblParent").removeClass("is-active");
	}
}

// 検討中からお問い合わせできる物件数の制限
function favChkCnt(chkElem) {
	var cnt = 0;
	chkElem.each(function() {
		if ($(this).prop("checked") == true) {
			cnt++;
		}
	});
	var not = chkElem.not(":checked");

	if (cnt >= 10) {
		not.attr("disabled", true);
	}
	else {
		not.attr("disabled", false);
	}
	if (cnt == 0) {
		$(".favoriteBtnEntry").addClass("is-disabled");
	} else {
		$(".favoriteBtnEntry").removeClass("is-disabled");
	}

	$(".chkTblNum").text(cnt);
}

// 物件一覧の検索追従
function fixedArticleListSearch(articleListSearchPos) {
	if ($(".fixedArticleListSearch")[0]) {
		var pos = $(window).scrollTop();
		if (pos > articleListSearchPos) {
			$(".fixedArticleListSearch").addClass("is-fixed");
		} else {
			$(".fixedArticleListSearch").removeClass("is-fixed");
		}
	}
}